import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class SignUpModal extends Component {
  state = {
    redirect: false,
  };

  redirectToSignUp = () => {
    this.setState(
      {
        redirect: true,
      },
      () => this.props.activateSignUp()
    );
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/signup" />;
    }
    return (
      <div>
        <h3 className="underline">New Customers</h3>
        <p>Sign up to our API to receive...</p>
        <ul>
          {/* <li>Customise the look of PNR Converter to reflect your business</li> */}
          <li>Free plan for low usage and to allow you to get set up</li>
          <li>
            The only PNR API to give you CO2 emissions, distance, transit time,
            flight duration and class of service
          </li>
          <li>Get the most detailted API response anywhere on the web</li>
          <li>No Contracts - Cancel at any time</li>
          <li>The cheapest webservice for PNR Conversion</li>
          <li>Get priority support for the API set up</li>
        </ul>
        <button className="secondary" onClick={() => this.redirectToSignUp()}>
          <p className="icon-signup-green">Sign Me Up</p>
        </button>
      </div>
    );
  }
}

export default SignUpModal;
